<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>

<v-row justify="center">

  </v-row>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <div class="row" style="margin-top:0px">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th style="text-align:left">#</th>
            <td style="text-align:right">{{ sale.sale_number }}</td>
          </tr>
          <tr>
            <th style="text-align:left">Date</th>
            <td style="text-align:right">{{ sale.created_at | formatDate }}</td>
          </tr>
          <tr>
            <th style="text-align:left">Heure</th>
            <td style="text-align:right">{{ sale.created_at | formatTime }}</td>
          </tr>
          <tr>
            <th style="text-align:left">Client</th>
            <td style="text-align:right">{{ sale.customer.full_name }}</td>
          </tr>
          <tr>
            <th style="text-align:left">Magasin</th>
            <td style="text-align:right">{{ sale.store.name }}</td>
          </tr>
          <tr>
            <th style="text-align:left">Agent</th>
            <td style="text-align:right">{{ sale.user.full_name }}</td>
          </tr>
          <tr>
            <th style="text-align:left">Taux du Jour</th>
            <td style="text-align:right">{{ Number(sale.daily_rate).toLocaleString() | formatNumber }}</td>
          </tr>
          <tr>
            <th style="text-align:left">Statut</th>
            <td style="text-align:right" v-if="sale.status == 1"><span class='badge badge-success'>Actif</span></td>
            <td style="text-align:right" v-if="sale.status == 0"><span class='badge badge-danger'>Annule</span></td>
          </tr>
        </tbody>
      </table>

      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th style="text-align:left">Produit</th>
            <th class="text-center">Quantité</th>
            <th class="text-center">Prix</th>
            <th class="text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in sale.sale_products" :key=product.id>
            <td style="text-align:left">{{ product.product.name }}</td>
            <td class="text-center">{{ product.quantity }}</td>
            <td class="text-center">{{ product.price | formatNumber }} {{ sale.rate.name }}</td>
            <td class="text-center">{{ product.total | formatNumber }} {{ sale.rate.name }}</td>
          </tr>
          
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3" style="text-align:right;border:1px solid white!important;border-right:1px solid #ddd!important">Sous-Total</th>
            <td class="text-center">{{ sale.subtotal | formatNumber }} {{ sale.rate.name }}</td>
          </tr>
          <tr v-if="sale.discount > 0">
            <th colspan="3" style="text-align:right;border:1px solid white!important;border-right:1px solid #ddd!important">Reduction ({{ sale.discount }}%)</th>
            <td class="text-center">{{ (sale.subtotal*sale.discount/100) | formatNumber }} {{ sale.rate.name }}</td>
          </tr>
          <tr v-if="sale.taxe > 0">
            <th colspan="3" style="text-align:right;border:1px solid white!important;border-right:1px solid #ddd!important" >Taxe</th>
            <td class="text-center">{{ sale.taxe | formatNumber }} {{ sale.rate.name }}}</td>
          </tr>
          <tr style="border-left:none!important">
            <th colspan="3" style="text-align:right;border:1px solid white!important;border-right:1px solid #ddd!important">Total</th>
            <td class="text-center">{{ sale.total | formatNumber }} {{ sale.rate.name }}</td>
          </tr>
        </tfoot>
      </table>

      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th style="text-align:left" colspan="2">Paiements</th>
          </tr>
          <tr>
            <th class="text-left">Méthode</th>
            <th class="text-center">Montant</th>
            <th class="text-right">Mémo</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in sale.payments" :key=payment.id>
            <td style="text-align:left">{{ payment.method.name }}</td>
            <td class="text-center" v-if="payment.rate_id == 2">{{ payment.amount | formatNumber }} USD</td>
            <td class="text-center" v-if="payment.rate_id == 1">{{ payment.amount | formatNumber }} HTG</td>
            <td class="text-right">{{ payment.memo }}</td>
          </tr>
          <tr>  
              <td class="text-left">Monnaie</td>
              <td class="text-center" v-if="sale.change_rate_id == 2 && sale.change_rate == sale.rate_id">{{ sale.change | formatNumber }} USD</td>
              <td class="text-center" v-if="sale.change_rate_id == 2 && sale.change_rate != sale.rate_id">{{ sale.change/sale.daily_rate | formatNumber }} USD</td>

              <td class="text-center" v-if="sale.change_rate_id == 1 && sale.change_rate == sale.rate_id">{{ sale.change | formatNumber }} HTG</td>
              <td class="text-center" v-if="sale.change_rate_id == 1 && sale.change_rate != sale.rate_id">{{ sale.change*sale.daily_rate | formatNumber }} HTG</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'SaleDetailsView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get("/sales/"+this.$route.params.id+"?include=store,device,sale_products.product,user,customer,rate,payments.method")
        .then(function (response) {
          console.log(response.data)
          self.sale = response.data
          self.enable = true   
          self.items = [
            {
              text: 'Ventes',
              disabled: false,
              href: '/sales',
            },
            {
              text: '#'+response.data.sale_number,
              disabled: false,
              href: '#',
            },
          ]  
        })
    },
  },
  mounted(){
    this.load()
  },
  data: () => ({
      items: [
        {
          text: 'Ventes',
          disabled: false,
          href: '/sales',
        },
      ],
      fluid: true,
      enable: false,
      sale: []
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .theme--dark.v-btn.v-btn--has-bg{
    background-color:#1867c0 !important;
  }

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #1976d2 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:-8px;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .color-red{
    color:red!important;
  }c
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>